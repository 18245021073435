<template>
  <div class="mb-50">
    <div v-loading="loading">
      <el-form
        :model="form"
        ref="form"
        label-width="92px"
        label-position="right"
        :inline="false"
        class="demo-form-inline"
      >
        <!-- ----------- -->
        <div class="border-b" style="margin-bottom: 30px">
          <el-row>
            <el-col :span="24">
              <el-form-item label="选择地区：" prop="activeDateIdx">
                <div style="display: flex; flex-wrap: wrap;">
                  <span
                    class="type-item"
                    @click="onTabs('', 'addressCode')"
                    :class="{ is_active: addressCode === '' }"
                  >全部</span
                  >
                  <span
                    class="type-item"
                    :class="{ is_active: addressCode == item.adCode }"
                    @click="onTabs(item.adCode, 'addressCode')"
                    v-for="(item, index) in addressData"
                    :key="index"
                  >
                    {{ item.name }}</span
                  >
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="需求类型：" prop="activeDateIdx">
                <div style="display: flex">
                  <span
                    class="type-item"
                    :class="{ is_active: dateTypeId == item.value }"
                    @click="onTabs(item.value, 'dateTypeId')"
                    v-for="(item, index) in dateList"
                    :key="index"
                  >
                    {{ item.name }}</span
                  >
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="专业分类：" prop="1">
                <div style="display: flex">
                  <span
                    class="type-item"
                    @click="onTabs('', 'selectTypeId')"
                    :class="{ is_active: selectTypeId === '' }"
                    >不限</span
                  >
                  <span
                    class="type-item"
                    :class="{ is_active: selectTypeId == item.id }"
                    @click="onTabs(item.id, 'selectTypeId')"
                    v-for="(item, index) in professionTypeList"
                    :key="index"
                  >
                    {{ item.name }}</span
                  >
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="注册等级：" prop="1">
                <div style="display: flex">
                  <span
                    class="type-item"
                    :class="{ is_active: category === item.value }"
                    @click="onTabs(item.value, 'category')"
                    v-for="(item, index) in levelList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="经验要求：" prop="1">
                <div style="display: flex">
                  <span
                    class="type-item"
                    :class="{ is_active: selectWorkAges === item }"
                    @click="onTabs(item, 'selectWorkAges')"
                    v-for="(item, index) in yearList"
                    :key="index"
                  >
                   {{item}}
                  </span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="合作性质：" prop="1">
                <div style="display: flex">
                  <span
                    class="type-item"
                    :class="{
                      is_active: natureCooperation === item.value,
                    }"
                    @click="onTabs(item.value, 'natureCooperation')"
                    v-for="(item, index) in cooperateType"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="24" v-show="isNeedBusiness">
              <el-form-item label="需求业务：" prop="1">
                <div style="display: flex">
                  <span
                    class="type-item"
                    @click="onTabs('', 'needBusiness')"
                    :class="{ is_active: needBusiness === '' }"
                  >不限</span
                  >
                  <span
                    class="type-item"
                    :class="{
                      is_active: needBusiness === item.name,
                    }"
                    @click="onTabs(item.name, 'needBusiness')"
                    v-for="(item, index) in businessTypeList"
                    :key="index"
                  >
                    {{ item.name }}
                  </span>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <!-- 列表 -->
        <div>
          <el-row>
            <el-table
              :data="tableData"
              :show-header="false"
              :expand-row-keys="expandedRows"
              row-key="id"
              border
              :fit="true"
            >
              <el-table-column style="height: 218px">
                <el-row slot-scope="{ row }" type="flex" align="middle">
                  <el-col :span="24">
                    <el-row align="top">
                      <el-col :span="24" style="padding: 0px 40px 5px 0px">
                        <div class="a-flex flex-between" v-show="!isNeedBusiness">
                          <div style="flex: 1; padding: 0 20px">
                            <div class="txt1">{{ row.contact | hideName }}</div>
                            <div class="list-t mr-40">
                              造价专业：<span>{{ row.specialized }}</span>
                            </div>
                            <div class="list-t">
                              工作年限：<span>{{ row.workAge }}</span>
                            </div>
                            <div class="list-t">
                              项目经验：<span>{{ row.describes }}</span>
                            </div>
                          </div>
                          <div class="btn-wh" @click="getPageOpen(row.id)">
                            查看详情
                          </div>
                        </div>
                        <div class="a-flex flex-between" v-show="isNeedBusiness">
                          <div style="flex: 1; padding: 0 20px">
                            <div class="txt1">{{ row.specialized }}</div>
                            <div class="list-t mr-40">
                              需求描述：<span>{{ row.specialized }}</span>
                            </div>
                            <div class="list-t">
                              发布时间：<span>{{ row.createTime }}</span>
                            </div>
                            <div class="list-t list-b">
                              <span v-for="(item, index) in row.needBusiness.split(',')" :key="index">{{item}}</span>
                            </div>
                          </div>
                          <div class="btn-wh" @click="getPageOpen(row.id)">
                            查看详情
                          </div>
                        </div>
                      </el-col>
                    </el-row>
                  </el-col>
                </el-row>
              </el-table-column>
            </el-table>
          </el-row>
          <!-- 分页 -->
          <div class="flex project_page">
            <el-pagination
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
              :page-size="size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { getCreativePersonnelPage, getWorkAgesList, urlCommonCategoryGetAppidIsTree } from "@/api/engineering";
import {mapState} from "vuex";
export default {
  data() {
    return {
      addressCode: '',
      dateList: [
        { name: "造价预算人员", value: 'person' },
        { name: "造价预算业务", value: 'business' },
      ],
      dateTypeId: "person", //需求类型
      professionTypeList: [],
      selectTypeId: "",
      levelList: [
        {
          name: "不限",
          value: "all",
        },
        {
          name: "造价员",
          value: "costEstimator",
        },
        {
          name: "预算员",
          value: "costEngineer",
        },
        {
          name: "一级注册造价工程师",
          value: "logonCostEngineer",
        },
        {
          name: "二级注册造价工程师",
          value: "2logonCostEngineer",
        },
      ],
      category: "all", //等级
      yearList: [],
      selectWorkAges: "不限",
      cooperateType: [
        {
          name: "不限",
          value: "all",
        },
        {
          name: "单次合作",
          value: "single",
        },
        {
          name: "长期合作",
          value: "forever",
        },
        {
          name: "项目承包",
          value: "contract",
        },
      ],
      natureCooperation: "all", //合作类型
      businessTypeList: [],
      needBusiness: "", //需求业务
      isNeedBusiness: false,

      expandedRows: [],
      activeDateIdx: 0,
      tableData: [],
      form: {},
      current: 1,
      size: 10,
      total: 0,
      loading: false
    };
  },
  filters: {
    hideName: function(name) {
      let firstLetter = name.charAt(0);
      let hiddenPart = '*'.repeat(name.length - 1);
      let hiddenName = firstLetter + hiddenPart;
      return hiddenName;
    }
  },
  computed: {
    ...mapState({
      addressData: state => state.address.addressData
    })
  },
  watch: {
    addressData: {
      handler(e) {
        return e
      },
      immediate:  true,
      deep: true
    }
  },
  mounted() {
    this.getWorkAgesList();
    this.getCommonCategoryGetAppidIsTree()
    this.getCreativePersonnelPage();

    this.handDate(1);
  },
  methods: {
    onTabs(val, type) {
      switch (type) {
        case 'dateTypeId':
          this.dateTypeId = val;
          if (val === 'business') {
            this.isNeedBusiness = true
          } else {
            this.isNeedBusiness = false
            this.needBusiness = ''
          }
          break;
        case 'selectTypeId':
          this.selectTypeId = val;
          break;
        case 'category':
          this.category = val;
          break;
        case 'selectWorkAges':
          this.selectWorkAges = val;
          break;
        case 'natureCooperation':
          this.natureCooperation = val;
          break;
        case 'needBusiness':
          this.needBusiness = val;
          break;
        case 'addressCode':
          this.addressCode = val;
          console.log(this.addressCode)
          break;
      }
      this.getCreativePersonnelPage()
    },
    async getCommonCategoryGetAppidIsTree() {
      const param = {
        appid: 'wx7ba99686668dd157'
      }
      const res = await urlCommonCategoryGetAppidIsTree(param)
      let resData = res.data
      this.businessTypeList = resData.find(item => item.label === "businessType")?.child || []
      this.professionTypeList = resData.find(item => item.label === "professionType")?.child || []
    },
    async getWorkAgesList() {
      const param = {};
      const res = await getWorkAgesList(param);
      this.yearList = res.data
    },
    // 造价预算业务列表
    async getCreativePersonnelPage() {
      this.loading = true;
      const param = {
        size: this.size,
        current: this.current,
        adCodes: [this.addressCode],
        category: this.category === 'all' ? '' : this.category,
        costType: this.dateTypeId,
        needBusiness: this.needBusiness,
        specialized: "",
        natureCooperation: this.natureCooperation === 'all' ? '' : this.natureCooperation,
        specializedId: this.selectTypeId,
        workAge: this.selectWorkAges === '不限' ? '' : this.selectWorkAges
      };
      // 获取API
      const res = await getCreativePersonnelPage(param);
      const { records, size, total } = res.data;
      this.size = size;
      this.total = total;
      this.tableData = records;
      this.loading = false;
    },
    // 详情
    getPageOpen(id) {
      if (this.dateTypeId === 'person') {
        this.$router.push({
          path: "/creative-personnel/detail/",
          query: { id },
        });
      } else {
        this.$router.push({
          path: "/creative-personnel/detailBusiness/",
          query: { id },
        });
      }
    },
    handDate(index) {
      this.activeDateIdx = index >= 2 ? index : -1;
    },
    onDownload(url) {
      console.log(111111111111);
    },
    handleSizeChange(e) {
      this.size = e;
      this.getCreativePersonnelPage();
    },
    handleCurrentChange(e) {
      this.current = e;
      this.getCreativePersonnelPage();
    },
  },
};
</script>

<style>
.is_active {
  color: #007ef2;
  background-color: #edf4ff;
  border-radius: 5px;
}
.a-flex {
  display: flex;
}

.afw-b {
  font-weight: bolder;
}
.list-z {
  text-align: left;
  font-weight: 500;
  color: #4e4e4e;
  line-height: 40px;
  margin-top: 25px;
  margin-bottom: 25px;
}
.border-b {
  width: 100%;
  /* height: 135px; */
  background: #ffffff;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #edf4ff;
  text-align: left;
  padding: 25px 20px 0px 20px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mr-40 {
  margin-right: 40px;
}
.txt1 {
  font-size: 24px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #4e4e4e;
  padding-top: 15px;
  margin-bottom: 20px;
}
.list-t {
  font-size: 14px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #4e4e4e;
  line-height: 32px;
}
.list-b {
  margin: 0 -4px;
}
.list-b > span {
  height: 26px;
  line-height: 26px;
  padding: 0 8px;
  margin: 0 4px;
  border: 1px solid #1890FF;
  border-radius: 4px;
  color: #1890FF;
}
.txt-wh {
  width: 840px;
  height: 38px;
  font-size: 16px;
  color: #21262d;
  line-height: 20px;
}
.btn-wh {
  font-size: 16px;
  font-family: Source Han Sans CN-Medium, Source Han Sans CN;
  font-weight: 500;
  color: #1b5c98;
  cursor: pointer;
}
.project_page {
  justify-content: flex-end;
  margin-top: 30px;
}

.flex {
  display: flex;
}
/* 左右 */
.flex-between {
  justify-content: space-between;
  align-items: center;
}
.el-table {
  width: 99.99% !important;
}
.type-item {
  white-space: nowrap;
  margin-right: 20px;
  padding: 0 10px;
  cursor: pointer;
}
</style>
